<template>
  <section class="warning-list" v-loading="loading">
    <div class="btn-wrap">
      <el-button size="small" type="primary" @click="addWarning">创建</el-button>
      <!-- <el-button size="small" type="primary" @click="editWarning">编辑</el-button>
      <el-button size="small" type="primary" @click="deleteWarning">删除</el-button> -->
    </div>
    <el-table style="margin-bottom: 50px;"
      :data="tableData"
      border max-height="calc(100% - 40px)"
      ref="table">
      <el-table-column  :resizable="false"
        prop="name"
        align="center"
        label="预警名称"/>

      <el-table-column  :resizable="false"
        prop="typeName"
        align="center"
        label="分类名称" width="200"/>
      <el-table-column  :resizable="false"
        prop="level"
        align="center"
        label="预警级别" width="80"/>
      <el-table-column  :resizable="false"
        align="center"
        label="预警核心词">
        <template scope="{ row }">
          <span v-if="row.coreWord === '' || row.coreWord === null">暂无</span>
          <span v-else>{{ row.coreWord }}</span>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false"  label="预警同现词" align="center">
        <template scope="{ row }">
          <span v-if="row.cooccurrenceWord === '' || row.cooccurrenceWord === null">暂无</span>
          <span v-else>{{ row.cooccurrenceWord }}</span>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false"  label="预警排除词" align="center">
        <template scope="{ row }">
          <span v-if="row.excludeWord === '' || row.excludeWord === null">暂无</span>
          <span v-else>{{ row.excludeWord }}</span>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="receiptTime"
        align="center"
        label="预警时间" width="120"/>
      <el-table-column  :resizable="false"  label="接收方式" align="center" width="150">
        <template scope="{ row }">
          <el-tag v-if="row.multWarningWay.indexOf('0') !== -1" size="small" style="margin-left: 3px; margin-right: 3px">邮箱</el-tag>
          <el-tag v-if="row.multWarningWay.indexOf('1') !== -1" size="small" style="margin-left: 3px; margin-right: 3px">微信</el-tag>
          <el-tag v-if="row.multWarningWay.indexOf('2') !== -1" size="small" style="margin-left: 3px; margin-right: 3px">APP</el-tag>
<!--          <span v-else-if="row.warningWay == 2">手机</span>-->
        </template>
      </el-table-column>
      <el-table-column  :resizable="false"
        align="center"
        label="预警状态" width="100">
        <template scope="{ row }">
          <el-switch
            v-model="row.isEnable"
            @change="updateStatus(row.id, row.isEnable)"
            :active-value=1
            :inactive-value=0
            active-color="#13ce66"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false"
        label="操作"
        align="center" width="150">
      <template scope="{ row }">
        <el-button type="text" @click="editWarning(row.id)">修改</el-button>
        <el-button type="text" @click="deleteWarning(row.id,row.warningSortId,row.receiveId)">删除</el-button>
      </template>
      </el-table-column>
    </el-table>
    <section class="list-bottom">
      <span class="count">共{{count}}条记录</span>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </section>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="conDel">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { listWarning, stateSwitchWarning, deleteWarning } from '@/api/settings/warning'
import eventHub from '@/utils/eventHub'
export default {
  data () {
    return ({
      loading: true,
      count: 0,
      tableData: [],
      currentPage: 1,
      size: 10,
      dialogVisible: false,
      selectedList: [],
      warningSortIdList: [],
      receiveId: []
    })
  },
  methods: {
    deleteWarning (id, warningSortId,receiveId) {
      this.selectedList.push(id)
      this.warningSortIdList.push(warningSortId)
      this.receiveId.push(receiveId)
      this.dialogVisible = true
    },
    async conDel () {
      const res = await deleteWarning({ id: this.selectedList, warningSortId: this.warningSortIdList, receiveId: this.receiveId })
      if (res.code === 0) {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.dialogVisible = false
        this.listAllWarning(this.currentPage, this.size)
      } else {
        this.dialogVisible = false
        this.$message.error(res.msg)
      }
    },
    handleClose () {
      this.dialogVisible = false
    },
    handleSizeChange (val) {
      this.size = val
      this.listAllWarning(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.listAllWarning(val, this.size)
    },
    addWarning () {
      this.$router.push('/settings/warning/add')
    },
    editWarning (id) {
      this.$router.push('/settings/warning/edit/' + id)
    },
    async listAllWarning (currentPage, size) {
      const res = await listWarning({ currentPage: currentPage, pageSize: size })
      this.tableData = res.data
      this.count = res.count
      this.selectedList = []
      this.loading = false
    },
    async updateStatus (id, isEnable) {
      const res = await stateSwitchWarning({ id: id, isEnable: isEnable })
      if (res.code === 0) {
        this.$message({
          message: res.msg,
          type: 'success'
        })
      } else {
        this.$message.error(res.msg)
      }
      this.listAllWarning(this.currentPage, this.size)
    }
  },
  mounted () {
    this.listAllWarning(this.currentPage, this.size)
    eventHub.$emit('msg', '预警设置')
  }
}
</script>

<style lang="scss">
  .warning-list {
    width: 100%;
    min-height: 100%;
    background: #fff;
    border-radius: 2px;
    padding: 20px 20px;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    .btn-wrap {
      margin-bottom: 25px;
    }

    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .count {
        line-height: 32px;
      }
    }
    .selected {
      background: rgba(64,158,255, .2);
    }
    .el-dialog {
      .prompt {
        height: 28px;
        display: flex;
        align-items: center;
        .el-icon-warning {
          font-size: 28px;
          color: #FF8B35;
          margin-right: 12px;
        }
      }
    }
  }
</style>
